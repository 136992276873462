import { toastController } from '@ionic/vue';

export enum ToastType {
  ERROR = 'danger',
  SUCCESS = 'success',
  DEFAULT = 'dark',
}
export const ToastDefaultDuration = 2000;

export const useToast = () => {
  const showToast = async (
    message: string,
    type = ToastType.DEFAULT,
    duration = ToastDefaultDuration
  ) => {
    const toast = await toastController.create({
      message: message,
      duration,
      color: type,
      position: 'top',
    });
    return toast.present();
  };

  const hideToast = async () => {
    await toastController.dismiss();
  };

  return {
    showToast,
    hideToast,
  };
};
