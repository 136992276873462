<template>
  <ion-page>
    <ion-header>
      <BaseToolbar
        :page-title="$t('views.add-token')"
        :has-back-button="hasBackButton"
      />
    </ion-header>
    <ion-content>
      <BaseContent class="add-token">
        <form @submit.prevent="submitToken">
          <BaseInput
            name="label"
            :label="$t('add-token.form.label.label')"
            :placeholder="$t('add-token.form.label.placeholder')"
            required
            v-model="token.label"
          />
          <BaseInput
            name="symbol"
            :label="$t('add-token.form.symbol.label')"
            :placeholder="$t('add-token.form.symbol.placeholder')"
            required
            v-model="token.symbol"
          />
          <BaseInput
            name="description"
            :label="$t('add-token.form.description.label')"
            :placeholder="$t('add-token.form.description.placeholder')"
            required
            textarea
            v-model="token.description"
          />
          <BaseInput
            name="marketCap"
            :label="$t('add-token.form.market-cap.label')"
            :placeholder="$t('add-token.form.market-cap.placeholder')"
            :input-mode="'decimal'"
            v-model="token.marketCap"
          />
          <BaseInput
            name="price"
            :label="$t('add-token.form.price.label')"
            :placeholder="$t('add-token.form.price.placeholder')"
            :input-mode="'decimal'"
            v-model="token.price"
          />
          <BaseToggle
            name="presale"
            :label="$t('add-token.form.presale.label')"
            v-model="token.preSale"
          />
          <BaseInput
            name="launchDate"
            :label="$t('add-token.form.launch-date.label')"
            :placeholder="$t('add-token.form.launch-date.placeholder')"
            required
            type="date"
            v-model="token.launchDate"
          />
          <BaseInput
            name="website"
            :label="$t('add-token.form.website.label')"
            :placeholder="$t('add-token.form.website.placeholder')"
            type="url"
            input-mode="url"
            v-model="token.web"
          />
          <BaseInput
            name="telegram"
            :label="$t('add-token.form.telegram.label')"
            :placeholder="$t('add-token.form.telegram.placeholder')"
            required
            type="url"
            input-mode="url"
            v-model="token.telegram"
          />
          <BaseInput
            name="twitter"
            :label="$t('add-token.form.twitter.label')"
            :placeholder="$t('add-token.form.twitter.placeholder')"
            type="url"
            input-mode="url"
            v-model="token.twitter"
          />
          <BaseInput
            name="discord"
            :label="$t('add-token.form.discord.label')"
            :placeholder="$t('add-token.form.discord.placeholder')"
            type="url"
            input-mode="url"
            v-model="token.discord"
          />
          <BaseInput
            name="reddit"
            :label="$t('add-token.form.reddit.label')"
            :placeholder="$t('add-token.form.reddit.placeholder')"
            type="url"
            input-mode="url"
            v-model="token.reddit"
          />
          <BaseInput
            name="logo"
            :label="$t('add-token.form.logo.label')"
            :placeholder="$t('add-token.form.logo.placeholder')"
            required
            v-model="token.logo"
          />
          <BaseInput
            name="chart"
            :label="$t('add-token.form.chart.label')"
            :placeholder="$t('add-token.form.chart.placeholder')"
            type="url"
            input-mode="url"
            v-model="token.chart"
          />
          <BaseSelect
            name="chain"
            :label="$t('add-token.form.chain.label')"
            :placeholder="$t('add-token.form.chain.placeholder')"
            :options="chainOptions"
            required
            v-model="token.chain"
          />
          <BaseInput
            name="address"
            :label="$t('add-token.form.address.label')"
            :sub-label="$t('add-token.form.address.sub-label')"
            :placeholder="$t('add-token.form.address.placeholder')"
            required
            v-model="token.address"
          />
          <BaseToggle
            v-if="isLogged"
            name="published"
            :label="$t('add-token.form.published.label')"
            v-model="token.published"
          />
          <BaseToggle
            v-if="isLogged"
            name="pushed"
            :label="$t('add-token.form.pushed.label')"
            v-model="token.pushed"
          />

          <ion-button
            color="primary"
            expand="block"
            :disabled="disabled"
            type="submit"
            v-text="$t('global.confirm')"
          />
        </form>
      </BaseContent>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseToggle from '@/components/base/BaseToggle.vue';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonButton,
  alertController,
} from '@ionic/vue';
import { useTokenForm } from '@/hooks/useTokenForm';
import { useRoute, useRouter } from 'vue-router';
import useStore from '@/store';
import { useI18n } from 'vue-i18n';
import { ToastType, useToast } from '@/hooks/useToast';
import { RouteName } from '@/router/route-const';

export default defineComponent({
  name: 'AddToken',
  components: {
    BaseToolbar,
    BaseContent,
    BaseInput,
    BaseSelect,
    BaseToggle,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const { showToast } = useToast();
    const { token, disabled, errors, editMode, submit } = useTokenForm();

    const isLogged = computed(() => store.getters.isLogged);

    const confirmSubmission = async () => {
      const alert = await alertController.create({
        header: t('add-token.confirm-submission-alert-title'),
        message: t('add-token.confirm-submission-alert-message'),
        buttons: [
          {
            text: t('global.ok'),
            handler: () => {
              router.push({ name: RouteName.HOME });
            },
          },
        ],
      });
      await alert.present();
    };

    const confirmEdition = async () => {
      router.go(-1);
      await showToast(
        t('add-token.confirm-edition-toast-message'),
        ToastType.SUCCESS
      );
    };

    const submitToken = () => {
      submit().then(() => {
        if (!errors.value) {
          editMode.value ? confirmEdition() : confirmSubmission();
        }
      });
    };

    const chainOptions = [
      {
        label: 'BSC',
        value: 'BSC',
      },
      {
        label: 'ETH',
        value: 'ETH',
      },
    ];

    const hasBackButton = ref<boolean>(route.params.hasBackButton === 'true');

    return {
      token,
      disabled,
      chainOptions,
      hasBackButton,
      isLogged,

      submitToken,
    };
  },
});
</script>

<style lang="scss" scoped>
.add-token {
}
</style>
