
import { defineComponent } from 'vue';
import { IonItem, IonToggle, IonLabel } from '@ionic/vue';

export default defineComponent({
  name: 'BaseToggle',
  emits: ['update:modelValue'],
  components: {
    IonItem,
    IonToggle,
    IonLabel,
  },
  props: {
    modelValue: Boolean,
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  setup(_props, { emit }) {
    const toggleCheck = (val: boolean) => {
      emit('update:modelValue', val);
    };

    return {
      toggleCheck,
    };
  },
});
