
import { computed, defineComponent, ref, watch } from 'vue';
import {
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  IonLabel,
  IonTextarea,
} from '@ionic/vue';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import useModelWrapper from '@/hooks/useModelWrapper';

/** @typedef {"date" | "datetime-local" | "email" | "month" | "number" | "password" | "search" | "tel" | "text" | "time" | "url" | "week"} InputType */
const InputType = {
  DATE: 'date',
  DATETIME_LOCAL: 'datetime-local',
  EMAIL: 'email',
  MONTH: 'month',
  NUMBER: 'number',
  PASSWORD: 'password',
  SEARCH: 'search',
  TEL: 'tel',
  TEXT: 'text',
  TIME: 'time',
  URL: 'url',
  WEEK: 'week',
};

/** @typedef {"none", "text", "tel", "url", "email", "numeric", "decimal"} InputModeType */
const InputModeType = {
  NONE: 'none',
  TEXT: 'text',
  TEL: 'tel',
  URL: 'url',
  EMAIL: 'email',
  NUMERIC: 'numeric',
  DECIMAL: 'decimal',
};

export default defineComponent({
  name: 'BaseInput',
  emits: ['update:modelValue'],
  components: {
    IonItem,
    IonInput,
    IonTextarea,
    IonButton,
    IonIcon,
    IonLabel,
  },
  props: {
    modelValue: { type: [Number, String, Date] },
    value: { type: [Number, String, Date] },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    subLabel: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: InputType.TEXT,
      validator: (v: string) => Object.values(InputType).includes(v),
    },
    inputMode: {
      type: String,
      default: InputModeType.TEXT,
      validator: (v: string) => Object.values(InputModeType).includes(v),
    },
    rows: {
      type: Number,
      default: 3,
    },
    textarea: Boolean,
    autocorrect: Boolean,
    clearInput: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
  },
  setup(props, { emit }) {
    const activeValue = useModelWrapper(props, emit);
    const canToggleType = computed(() => props.type === InputType.PASSWORD);
    const activeType = ref(props.type);
    const activeIcon = computed(() =>
      activeType.value === InputType.PASSWORD ? eyeOutline : eyeOffOutline
    );

    const toggleType = () => {
      if (activeType.value === InputType.PASSWORD) {
        activeType.value = InputType.TEXT;
      } else if (activeType.value === InputType.TEXT) {
        activeType.value = InputType.PASSWORD;
      }
    };

    watch(
      () => props.modelValue,
      () => {
        if (props.inputMode === InputModeType.DECIMAL) {
          activeValue.value = activeValue.value.replace(/,/g, '.');
        }
      }
    );

    return {
      activeValue,
      canToggleType,
      activeType,
      activeIcon,

      toggleType,
    };
  },
});
