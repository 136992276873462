<template>
  <ion-item class="base-toggle" lines="none">
    <ion-label class="base-toggle__label" v-text="label" />
    <ion-toggle
      :name="name"
      :disabled="disabled"
      @ionChange="toggleCheck(!modelValue)"
      :checked="modelValue"
    />
  </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonItem, IonToggle, IonLabel } from '@ionic/vue';

export default defineComponent({
  name: 'BaseToggle',
  emits: ['update:modelValue'],
  components: {
    IonItem,
    IonToggle,
    IonLabel,
  },
  props: {
    modelValue: Boolean,
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  setup(_props, { emit }) {
    const toggleCheck = (val: boolean) => {
      emit('update:modelValue', val);
    };

    return {
      toggleCheck,
    };
  },
});
</script>

<style lang="scss" scoped>
.base-toggle {
  --padding-start: 0.625em;

  &__label {
    font-size: var(--base-label-font-size);
  }
}
</style>
