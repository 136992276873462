
import { defineComponent, PropType } from 'vue';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';

export default defineComponent({
  name: 'BaseSelect',
  emits: ['update:modelValue'],
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    modelValue: { type: [Number, String] },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    options: {
      type: Array as PropType<
        Array<{ label: string; value: string | number | boolean }>
      >,
      default: () => [],
    },
    disabled: Boolean,
    required: Boolean,
  },
});
