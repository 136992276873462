
import { computed, defineComponent, ref } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseToggle from '@/components/base/BaseToggle.vue';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonButton,
  alertController,
} from '@ionic/vue';
import { useTokenForm } from '@/hooks/useTokenForm';
import { useRoute, useRouter } from 'vue-router';
import useStore from '@/store';
import { useI18n } from 'vue-i18n';
import { ToastType, useToast } from '@/hooks/useToast';
import { RouteName } from '@/router/route-const';

export default defineComponent({
  name: 'AddToken',
  components: {
    BaseToolbar,
    BaseContent,
    BaseInput,
    BaseSelect,
    BaseToggle,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const { showToast } = useToast();
    const { token, disabled, errors, editMode, submit } = useTokenForm();

    const isLogged = computed(() => store.getters.isLogged);

    const confirmSubmission = async () => {
      const alert = await alertController.create({
        header: t('add-token.confirm-submission-alert-title'),
        message: t('add-token.confirm-submission-alert-message'),
        buttons: [
          {
            text: t('global.ok'),
            handler: () => {
              router.push({ name: RouteName.HOME });
            },
          },
        ],
      });
      await alert.present();
    };

    const confirmEdition = async () => {
      router.go(-1);
      await showToast(
        t('add-token.confirm-edition-toast-message'),
        ToastType.SUCCESS
      );
    };

    const submitToken = () => {
      submit().then(() => {
        if (!errors.value) {
          editMode.value ? confirmEdition() : confirmSubmission();
        }
      });
    };

    const chainOptions = [
      {
        label: 'BSC',
        value: 'BSC',
      },
      {
        label: 'ETH',
        value: 'ETH',
      },
    ];

    const hasBackButton = ref<boolean>(route.params.hasBackButton === 'true');

    return {
      token,
      disabled,
      chainOptions,
      hasBackButton,
      isLogged,

      submitToken,
    };
  },
});
