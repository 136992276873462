import { computed } from 'vue';

export const useModelWrapper = (
  props: any,
  emit: (event: any, ...args: any[]) => void,
  name = 'modelValue'
) => {
  return computed({
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value),
  });
};

export default useModelWrapper;
