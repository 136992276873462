<template>
  <ion-item class="base-input" lines="none">
    <ion-label
      v-if="label"
      position="stacked"
      v-text="label"
      class="base-input__label"
      :class="{ 'base-input__label--required': required }"
    />
    <span v-if="subLabel" class="base-input__sub-label" v-text="subLabel" />
    <div class="base-input__input-wrapper">
      <component
        :is="!textarea ? 'ion-input' : 'ion-textarea'"
        class="base-input__input"
        :name="name"
        :placeholder="placeholder"
        :autocorrect="autocorrect"
        :clear-input="clearInput"
        :inputmode="inputMode"
        :type="activeType"
        :readonly="readonly"
        :value="activeValue ? activeValue : value ? value : ''"
        :required="required"
        :rows="rows"
        autocomplete="off"
        @ionInput="$emit('update:modelValue', $event.target.value)"
      />
      <ion-button
        v-if="canToggleType"
        slot="end"
        color="medium"
        fill="clear"
        @click="toggleType"
        size="small"
      >
        <ion-icon slot="icon-only" :icon="activeIcon"></ion-icon>
      </ion-button>
    </div>
  </ion-item>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import {
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  IonLabel,
  IonTextarea,
} from '@ionic/vue';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import useModelWrapper from '@/hooks/useModelWrapper';

/** @typedef {"date" | "datetime-local" | "email" | "month" | "number" | "password" | "search" | "tel" | "text" | "time" | "url" | "week"} InputType */
const InputType = {
  DATE: 'date',
  DATETIME_LOCAL: 'datetime-local',
  EMAIL: 'email',
  MONTH: 'month',
  NUMBER: 'number',
  PASSWORD: 'password',
  SEARCH: 'search',
  TEL: 'tel',
  TEXT: 'text',
  TIME: 'time',
  URL: 'url',
  WEEK: 'week',
};

/** @typedef {"none", "text", "tel", "url", "email", "numeric", "decimal"} InputModeType */
const InputModeType = {
  NONE: 'none',
  TEXT: 'text',
  TEL: 'tel',
  URL: 'url',
  EMAIL: 'email',
  NUMERIC: 'numeric',
  DECIMAL: 'decimal',
};

export default defineComponent({
  name: 'BaseInput',
  emits: ['update:modelValue'],
  components: {
    IonItem,
    IonInput,
    IonTextarea,
    IonButton,
    IonIcon,
    IonLabel,
  },
  props: {
    modelValue: { type: [Number, String, Date] },
    value: { type: [Number, String, Date] },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    subLabel: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: InputType.TEXT,
      validator: (v: string) => Object.values(InputType).includes(v),
    },
    inputMode: {
      type: String,
      default: InputModeType.TEXT,
      validator: (v: string) => Object.values(InputModeType).includes(v),
    },
    rows: {
      type: Number,
      default: 3,
    },
    textarea: Boolean,
    autocorrect: Boolean,
    clearInput: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
  },
  setup(props, { emit }) {
    const activeValue = useModelWrapper(props, emit);
    const canToggleType = computed(() => props.type === InputType.PASSWORD);
    const activeType = ref(props.type);
    const activeIcon = computed(() =>
      activeType.value === InputType.PASSWORD ? eyeOutline : eyeOffOutline
    );

    const toggleType = () => {
      if (activeType.value === InputType.PASSWORD) {
        activeType.value = InputType.TEXT;
      } else if (activeType.value === InputType.TEXT) {
        activeType.value = InputType.PASSWORD;
      }
    };

    watch(
      () => props.modelValue,
      () => {
        if (props.inputMode === InputModeType.DECIMAL) {
          activeValue.value = activeValue.value.replace(/,/g, '.');
        }
      }
    );

    return {
      activeValue,
      canToggleType,
      activeType,
      activeIcon,

      toggleType,
    };
  },
});
</script>

<style lang="scss" scoped>
.base-input {
  --background: transparent;
  --padding-start: 0.625em;
  margin-bottom: 1em;

  &__label {
    margin-bottom: 0.5em;
    font-size: var(--base-label-font-size);
    &--required {
      &::after {
        content: '*';
        color: var(--ion-color-danger);
        font-weight: var(--fw-bold);
        margin-left: 0.25em;
      }
    }
  }

  &__sub-label {
    color: var(--ion-color-medium);
    font-size: 0.75em;
    font-style: italic;
    margin-bottom: 0.5rem;
  }

  &__input-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid var(--ion-color-primary);
    border-radius: 0.5em;
  }

  &__input {
    text-align: center;
    --placeholder-color: var(--ion-color-primary-tint);
    --padding-start: 0.5em;
    --padding-end: 0.5em;
    --color: var(--default-text-color);
    --border-radius: var(--default-border-radius);
  }
}
</style>
