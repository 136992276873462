<template>
  <ion-item class="base-select" lines="none">
    <ion-label
      v-text="label"
      class="base-select__label"
      :class="{ 'base-select__label--required': required }"
    />
    <ion-select
      :disabled="disabled"
      :value="modelValue"
      @ionChange="$emit('update:modelValue', $event.target.value)"
      :ok-text="$t('global.ok')"
      :cancel-text="$t('global.cancel')"
      :name="name"
      :required="required"
      :placeholder="placeholder"
    >
      <ion-select-option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        v-text="option.label"
      />
    </ion-select>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';

export default defineComponent({
  name: 'BaseSelect',
  emits: ['update:modelValue'],
  components: {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    modelValue: { type: [Number, String] },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    options: {
      type: Array as PropType<
        Array<{ label: string; value: string | number | boolean }>
      >,
      default: () => [],
    },
    disabled: Boolean,
    required: Boolean,
  },
});
</script>

<style lang="scss" scoped>
.base-select {
  --padding-start: 0.625em;

  &__label {
    font-size: var(--base-label-font-size);
    &--required {
      &::after {
        content: '*';
        color: var(--ion-color-danger);
        font-weight: var(--fw-bold);
        margin-left: 0.25em;
      }
    }
  }
}
</style>
