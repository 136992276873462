import { computed, ref, WritableComputedRef } from 'vue';
import useStore from '@/store';
import { ActionTypes as TokenAction } from '@/store/modules/token/actions';
import { Token } from '@/interfaces/token';
import { useRoute } from 'vue-router';

export const useTokenForm = () => {
  const store = useStore();
  const route = useRoute();

  const editMode = ref<boolean>(route.params.editMode === 'true');

  const currentToken: WritableComputedRef<Token | undefined> = computed(
    () => store.getters.currentToken
  );

  const token = ref<Token>({
    label: '',
    symbol: '',
    description: '',
    launchDate: new Date().toISOString().split('T')[0],
    logo: '',
    telegram: '',
    twitter: '',
    web: '',
    reddit: '',
    discord: '',
    chart: '',
    chain: '',
    address: '',
    price: 0,
    marketCap: 0,
    voteCount: 0,
    preSale: false,
  });

  if (editMode.value && currentToken.value) {
    token.value = { ...currentToken.value };
    token.value.launchDate = new Date(currentToken.value.launchDate)
      .toISOString()
      .split('T')[0];
  }

  const errors = ref();
  const disabled = ref<boolean>(false);
  const isLoading = ref<boolean>(false);

  const submit = async () => {
    errors.value = undefined;
    disabled.value = true;
    isLoading.value = true;
    try {
      editMode.value
        ? await store.dispatch(TokenAction.updateToken, token.value)
        : await store.dispatch(TokenAction.createNewToken, token.value);
    } catch (err) {
      errors.value = err.response.data.message || err.response.data.errors;
    }
    disabled.value = false;
    isLoading.value = false;
  };

  return {
    token,
    errors,
    disabled,
    isLoading,
    editMode,

    submit,
  };
};
